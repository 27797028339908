
import Layout from 'themes/layouts/Home'
import { Carousel , Button, Row, Col, Modal, Divider } from 'antd';
import Slider from "react-slick";
import {useState} from 'react'
import useBaseHooks from "themes/hooks/BaseHooks";

const Index = () => {
  const { t } = useBaseHooks();
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible5, setVisible5] = useState(false);
  const [visible6, setVisible6] = useState(false);
  const [visible7, setVisible7] = useState(false);
  const [visible8, setVisible8] = useState(false);
  const [visible9, setVisible9] = useState(false);

  const showModal = (stt: number) => {
    if(stt === 1) setVisible1(true)
    if(stt === 2) setVisible2(true)
    if(stt === 3) setVisible3(true)
    if(stt === 4) setVisible4(true)
    if(stt === 5) setVisible5(true)
    if(stt === 6) setVisible6(true)
    if(stt === 7) setVisible7(true)
    if(stt === 8) setVisible8(true)
    if(stt === 9) setVisible9(true)
  };

  const handleOk = (stt: number) => {
    if(stt === 1) setVisible1(false)
    if(stt === 2) setVisible2(false)
    if(stt === 3) setVisible3(false)
    if(stt === 4) setVisible4(false)
    if(stt === 5) setVisible5(false)
    if(stt === 6) setVisible6(false)
    if(stt === 7) setVisible7(false)
    if(stt === 8) setVisible8(false)
    if(stt === 9) setVisible9(false)
  };

  const handleCancel = (stt: number) => {
    if(stt === 1) setVisible1(false)
    if(stt === 2) setVisible2(false)
    if(stt === 3) setVisible3(false)
    if(stt === 4) setVisible4(false)
    if(stt === 5) setVisible5(false)
    if(stt === 6) setVisible6(false)
    if(stt === 7) setVisible7(false)
    if(stt === 8) setVisible8(false)
    if(stt === 9) setVisible9(false)
  };
  
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplaySpeed: 3000,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplaySpeed: 3500,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          autoplaySpeed: 4000,
          slidesToScroll: 2
        }
      }
    ]
  };
  
  return <Layout>
    <div id="banner-body-main-mqn" className="blocks">
      <div id="home" className="slider-img-header block">
      <Carousel autoplay>
        <div>
          <img src="/static/img/img1.jpeg" alt="hinhanh"/>
        </div>
        <div>
          <img src="/static/img/img2.jpeg" alt="hinhanh"/>
        </div>
        <div>
          <img src="/static/img/img3.jpeg" alt="hinhanh"/>
        </div>
        <div>
          <img src="/static/img/img-slide-4.jpeg" alt="hinhanh"/>
        </div>
      </Carousel>
        <div className="mouse-scroll-main-mqn">
         <a href="#about">
          <div className="moving-mouse-holder">
              <div className="mouse">
                <div className="mouse-button">&nbsp;</div>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div id="about" className="about-pro-main-mqn  block">
        <div className="container">
          <div className="title-about-main">
            <div className="title">{t('pages:about.title')}</div>
            <img src="/static/img/lineHeight.jpeg" alt="hinhanh"/>
          </div>
          <Row gutter={24}>
            <Col xs={24} md={24} lg={12} xl={13}>
              <div className="item-content-about-main">
                <h1>{t('pages:about.titleCompany')}</h1>
                <p>{t('pages:about.description')}</p>
                <h3 className="sologanMqn">{t('pages:about.slogan')}</h3>
              </div>
            </Col>
            <Col xs={24} md={24} lg={12} xl={11}>
              <div className="video-main-about-mqn">
                <iframe src={t('pages:about.youtube')}/>
              </div>
            </Col>
          </Row>
        </div>
        <div className="detail-about-us-mqn-main">
          <div className="container">
            <div className="content-detail-about-us-mqn">
              <div className="item-content-map-company-main">
              <h2>{t('pages:about.numberCompany')}</h2>
                <ul>
                  <li><span>{t('pages:about.companyJp')}</span></li>
                  <li><span>{t('pages:about.companySing')}</span></li>
                  <li><span>{t('pages:about.companyNo')}</span></li>
                  <li><span>{t('pages:about.companyVn')}</span></li>
                </ul>
                <h3><img src="/static/img/user.jpeg" alt="icon"/> {t('pages:about.staff')}</h3>
                <h3><img src="/static/img/money.jpeg" alt="icon"/> {t('pages:about.authorizedCapital')}</h3>
                <h3><img src="/static/img/agreement.jpeg" alt="icon"/> {t('pages:about.certificate')}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="services" className="services-banner-main-mqn block">
        <div className="container">
          <div className="item-title-services">
            <div className="title">{t('pages:service.title')}</div>
            <img src="/static/img/lineWhite.jpeg" alt="hinhanh"/>
          </div>
          <Row gutter={24}>
            <Col xs={24} md={24} lg={8} xl={8}>
              <div className="item-background-serviecs">
                <div className="item-serviecs-main">
                  <img src="/static/img/gear.jpeg" alt="hinhanh"/>
                  <h1>{t('pages:service.ITsolutions')}</h1>
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={8} xl={8}>
              <div className="item-background-serviecs">
                <div className="item-serviecs-main">
                  <img src="/static/img/outsourcing.jpeg" alt="hinhanh"/>
                  <h1>{t('pages:service.outsourcing')}</h1>
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={8} xl={8}>
              <div className="item-background-serviecs">
                <div className="item-serviecs-main">
                  <img src="/static/img/idea.jpeg" alt="hinhanh"/>
                  <h1>{t('pages:service.R&B')}</h1>
                </div>
              </div>
            </Col>
          </Row>
          
          <div className="item-rate-product-main">
            <Row gutter={24}>
              <Col xs={24} sm={10} md={6} lg={4} xl={5}>
                <div className="backborder">
                  <div className="backPro">
                    <div className="contentTextReview">
                        <h1>{t('pages:service.numberProject')}</h1>
                        <h2>{t('pages:service.descriptionProject')}</h2>
                      </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={10} md={6} lg={4} xl={5}>
                <div className="backborder">
                  <div className="backPro">
                    <div className="contentTextReview">
                        <h1>{t('pages:service.numberCustomer')}</h1>
                        <h2>{t('pages:service.descriptionCustomer')}</h2>
                      </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={10} md={6} lg={4} xl={5}>
                <div className="backborder">
                  <div className="backPro">
                    <div className="contentTextReview">
                        <h1>{t('pages:service.numberinvent')}</h1>
                        <h2>{t('pages:service.descriptioninvent')}</h2>
                      </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={10} md={9} lg={4} xl={5}>
                <div className="backborder item-rate-mobile">
                  <div className="backPro">
                    <div className="contentTextReview">
                        <h1>{t('pages:service.numberProduct')}</h1>
                        <h2>{t('pages:service.descriptionProduct')}</h2>
                      </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={10} md={9} lg={4} xl={4}>
                <div className="backborder item-rate-mobile2">
                  <div className="backPro">
                    <div className="contentTextReview">
                        <h1>{t('pages:service.numberResearch')}</h1>
                        <h2>{t('pages:service.descriptionResearch')}</h2>
                      </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="line-rate-mqn-main"></div>
          </div>
        </div>
      </div>

      <div id="product" className="product-main-pro-mqn-main block">
        <div className="container">
          <div className="title-banner-product">
            <div className="title">{t('pages:product.title')}</div>
            <img src="/static/img/lineHeight.jpeg" alt="hinhanh"/>
            <p>{t('pages:product.description')}</p>
          </div>
          <Row gutter={24}>
            <Col xs={24} md={12} lg={12} xl={12}>
             <div className="item-pro-mqn">
                <div className="item-product-mqn-pro">
                  <div className="item-title-product mediaProduct">
                    <img src="/static/img/digital-marketing.jpeg" alt="hinhanh"/>
                    <h1>{t('pages:product.ipDisplay')}</h1>
                  </div>
                </div>
                <div className="item-content-pro-mqn itemFixMainProduct">
                  <ul>
                    <li>
                      <img src="/static/img/boost.jpeg" alt="hinhanh"/>
                      <p>{t('pages:product.solutionIp')}</p>
                    </li>
                    <li>
                      <img src="/static/img/team.jpeg" alt="hinhanh"/>
                      <p>{t('pages:product.projectIp')}</p>
                    </li>
                  </ul>
                  <Button><a href="/static/img/ip-display.pdf" target="_blank">{t('common:Button.showMore')}</a></Button>
               </div>
             </div>
            </Col>

            <Col xs={24} md={12} lg={12} xl={12}>
             <div className="item-pro-mqn">
                <div className="item-product-mqn-pro">
                  <div className="item-title-product mediaProduct">
                    <img src="/static/img/cctv.jpeg" alt="hinhanh"/>
                    <h1>{t('pages:product.cameraAi')}</h1>
                  </div>
                </div>
                <div className="item-content-pro-mqn itemFixMainProduct">
                  <ul>
                    <li>
                      <img src="/static/img/boost.jpeg" alt="hinhanh"/>
                      <p>{t('pages:product.solutionCamera')}</p>
                    </li>
                    <li>
                      <img src="/static/img/team.jpeg" alt="hinhanh"/>
                      <p>{t('pages:product.projectCamera')}</p>
                    </li>
                  </ul>
                  <Button><a href="/static/img/box-ai.pdf" target="_blank">{t('common:Button.showMore')}</a></Button>
                    {/* <Modal
                      title="THÔNG TIN CAMERA AI"
                      visible={visible9}
                      onOk={() => handleOk(9)}
                      onCancel={() => handleCancel(9)}
                      width="80%"
                      footer={[
                        <Button key="back" onClick={() => handleCancel(9)}>
                          Đóng
                        </Button>,
                      ]}
                    >
                    <div className="detail-camera-ai-main-mqn">
                      <h1>Giải pháp A.I Camera của MQ ICT Solutions- lựa chọn thông minh</h1>
                        <Row gutter={24}>
                            <Col xl={12}>
                              <h2>1-Mô hình xử lý phi tập trung</h2>
                              <p>Mỗi camera trở thành thiết bị độc lập. Trong trường hợp yêu cầu cơ sở dữ liệu trung tâm, camera sẽ chỉ gửi kết quả đã xử lý. Nó làm cho giải pháp của MQ ICT Solutions dễ cài đặt, dễ bảo trì và dễ triển khai trên quy mô lớn trong thực tế.</p>
                            </Col>
                            <Col xl={12}>
                              <h2>2-Dễ dàng lắp đặt và triển khai</h2>
                              <p>Camera A.I tự động chụp ảnh khi khách hàng ở trong khu vực quan sát của camera. Chỉ cần MỘT ẢNH của mỗi khách hàng được chụp lại trong khi họ đi lại hành động như bình thường và vẫn giữ độ chính xác cao trên 95%.</p>
                            </Col>
                            <Col xl={12}>
                              <h2>3-Thuật toán A.I đặc biệt và cơ chế lưu trữ dữ liệu</h2>
                              <p>Mặc dù phần cứng giá rẻ (không phải phần cứng mạnh), chỉ với 1 hình ảnh input, thuật toán được phát triển đặc biệt của chúng tôi đảm bảo độ chính xác cao hơn 95%.</p>
                            </Col>
                            <Col xl={12}>
                              <h2>4-Cơ chế lưu trữ dữ liệu độc đáo</h2>
                              <p>Với cơ chế lưu trữ dữ liệu đặc biệt và thẻ SD 3 GB, A.I Camera có thể lưu trữ tới 150.000 khuôn mặt. Vì dữ liệu hình ảnh không được lưu trong camera, nếu camera bị thất lạc, tất cả dữ liệu trong camera sẽ được bảo mật.</p>
                            </Col>
                            <Col xl={12}>
                              <h2>5-Phần cứng chi phí thấp nhưng nhận diện được nhiều mặt trong thời gian thực</h2>
                              <p>Phần cứng với giá cạnh tranh.</p>
                              <p>Nhận diện khuôn mặt dưới 1 giây. A.I camera có thể nhận diện tối đa 20 khuôn mặt cùng một lúc tùy thuộc vào điều kiện ánh sáng.</p>
                            </Col>
                            <Col xl={12}>
                              <h2>6-Khách có thể hành động như bình thường, không cần chụp nhiều ảnh </h2>
                              <p>Camera nhỏ gọn, chi phí thấp + dễ tích hợp với hệ thống hiện có (CRM, cơ sở dữ liệu).</p>
                              <p>Chỉ cần năng lượng để bắt đầu sử dụng.</p>
                            </Col>
                        </Row>
                        <h3>Các case study đã triển khai</h3>
                          <Row gutter={24}>
                            <Col xs={24} md={24} lg={12} xl={6}>
                              <img src="/static/img/camera1.jpeg" alt="hinhanh"/>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={6}>
                              <img src="/static/img/camera2.jpeg" alt="hinhanh"/>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={6}>
                              <img src="/static/img/camera3.jpeg" alt="hinhanh"/>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={6}>
                              <img src="/static/img/camera4.jpeg" alt="hinhanh"/>
                            </Col>
                          </Row>
                        <h3>Video giới thiệu hệ thống</h3>
                          <Row gutter={24}>
                            <Col xs={24} md={24} lg={12} xl={6}>
                              <iframe src="https://www.youtube.com/embed/lHdYKYMB88A"></iframe>
                              <h4>Video giới thiệu về công nghệ</h4>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={6}>
                              <iframe src="https://www.youtube.com/embed/q8ntuCqXaJg"></iframe>
                              <h4>Demo hệ thống camera A.I xử lý phân tán nhiều camera cho an ninh/công an</h4>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={6}>
                              <iframe src="https://www.youtube.com/embed/iA3s5YKYerk"></iframe>
                              <h4>Giải pháp camera A.I tại triển lãm Osaka Intex 2020 - Japan</h4>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={6}>
                              <iframe src="https://www.youtube.com/embed/ezdfMwFewlw"></iframe>
                              <h4>Giải pháp camera A.I cho điểm danh tự động triển khai tại cao đẳng y tế Hà Tây</h4>
                            </Col>
                          </Row>
                    </div>
                  </Modal> */}
               </div>
             </div>
            </Col>

            {/* <Col xs={24} md={12} lg={12} xl={12}>
             <div className="item-pro-mqn">
                <div className="item-product-mqn-pro">
                  <div className="item-title-product">
                    <img src="/static/img/dohu.jpeg" alt="hinhanh"/>
                    <h1>{t('pages:product.dohu')}</h1>
                  </div>
                </div>
                <div className="item-content-pro-mqn itemFixMainProduct">
                  <ul>
                    <li>
                      <img src="/static/img/boost.jpeg" alt="hinhanh"/>
                      <p>{t('pages:product.solutionDohu')}</p>
                    </li>
                    <li>
                      <img src="/static/img/team.jpeg" alt="hinhanh"/>
                      <p>{t('pages:product.projectDohu')}</p>
                    </li>
                  </ul>
                  <Button><a href="https://dohu.vn/" target="_blank">{t('common:Button.showMore')}</a></Button>
               </div>
             </div>
            </Col>

            <Col xs={24} md={12} lg={12} xl={12}>
             <div className="item-pro-mqn">
                <div className="item-product-mqn-pro">
                  <div className="item-title-product fiup">
                    <img src="/static/img/fiup.jpeg" alt="hinhanh"/>
                    <h1>{t('pages:product.fiup')}</h1>
                  </div>
                </div>
                <div className="item-content-pro-mqn itemFixMainProduct">
                  <ul>
                    <li>
                      <img src="/static/img/boost.jpeg" alt="hinhanh"/>
                      <p>{t('pages:product.solutionFiup')}</p>
                    </li>
                    <li>
                      <img src="/static/img/team.jpeg" alt="hinhanh"/>
                      <p>{t('pages:product.projectFiup')}</p>
                    </li>
                  </ul>
                  <Button><a href="https://fiup.vn/" target="_blank">{t('common:Button.showMore')}</a></Button>
               </div>
             </div>
            </Col> */}
          </Row>
        </div>
      </div>

      <div id="customers" className="testimonials-main-banner-mqn block">
        <div className="container">
          <div className="title-main-testimonials">
            <div className="title">{t('pages:customer.title')}</div>
            <img src="/static/img/lineWhite.jpeg" alt="hinhanh"/>
          </div>
          <div className="slide-testimonials-main-pro-mqn">
          <Carousel>
            <div className="item-testimonoals">
              <Row gutter={24}>
                <Col xs={24} md={24} lg={12} xl={12}>
                  <div className="item-info-testimoals" onClick={() => showModal(1)}>
                    <Row gutter={24}>
                      <Col xs={24} md={8} lg={8} xl={8}>
                        <img src="/static/img/IkkiImage_avatar.jpeg" alt="hinhanh"/>
                      </Col>
                      <Col xs={24} md={16} lg={16} xl={16}>
                        <div className="company">{t('pages:customer.company1')}</div>
                        <div className="info">{t('pages:customer.info1')}</div>
                        <div className="info">{t('pages:customer.name1')}</div>
                        <div className="description">{t('pages:customer.descrtion1')}</div>
                      </Col>
                    </Row>
                  </div>
                  <Modal
                    title={t('pages:customer.titleModalFeedback')}
                    visible={visible1}
                    onOk={() => handleOk(1)}
                    onCancel={() => handleCancel(1)}
                    width="100%"
                    footer={[
                      <Button key="back" onClick={() => handleCancel(1)}>
                       {t('common:Button.close')}
                      </Button>,
                    ]}
                  >
                    <div className="item-detail-content-customer">
                      <img src="/static/img/IkkiImage_avatar.jpeg" className="avataDEtailCustomer" alt="hinhanh"/>
                      <div className="nameModalMqn">{t('pages:customer.info1')} {t('pages:customer.name1')}</div>
                      <p>{t('pages:customer.descFull1')}</p>
                      <div className="item-content-company-custormer-mqn">
                      <Row>
                      <Col xs={24} md={10} lg={10} xl={10} offset={14}>
                      <div className="companyModal">{t('pages:customer.company1')}</div>
                        <div className="infoModal">{t('pages:customer.info1')}</div>
                        <div className="nameModal">{t('pages:customer.name1')}</div>
                      </Col>
                      </Row>
                      </div>
                    </div>
                  </Modal>
                </Col>
                <Col xs={24} md={24} lg={12} xl={12}>
                  <div className="item-info-testimoals" onClick={() => showModal(2)}>
                    <Row gutter={24}>
                      <Col xs={24} md={8} lg={8} xl={8}>
                        <img src="/static/img/Kawamoto_avatar.jpeg" alt="hinhanh"/>
                      </Col>
                      <Col xs={24} md={16} lg={16} xl={16}>
                      <div className="company">{t('pages:customer.company2')}</div>
                        <div className="info">{t('pages:customer.info2')}</div>
                        <div className="info">{t('pages:customer.name2')}</div>
                        <div className="description">{t('pages:customer.descrtion2')}</div>
                      </Col>
                    </Row>
                  </div>
                  <Modal
                    title={t('pages:customer.titleModalFeedback')}
                    visible={visible2}
                    onOk={() => handleOk(2)}
                    onCancel={() => handleCancel(2)}
                    width="100%"
                    footer={[
                      <Button key="back" onClick={() => handleCancel(2)}>
                        {t('common:Button.close')}
                      </Button>,
                    ]}
                  >
                    <div className="item-detail-content-customer">
                      <img src="/static/img/Kawamoto_avatar.jpeg" className="avataDEtailCustomer" alt="hinhanh"/>
                      <div className="nameModalMqn">{t('pages:customer.info2')} {t('pages:customer.name2')}</div>
                      <p>{t('pages:customer.descFull2')}</p>
                      <div className="item-content-company-custormer-mqn">
                      <Row>
                      <Col xs={24} md={10} lg={10} xl={10} offset={14}>
                      <div className="companyModal">{t('pages:customer.company2')}</div>
                        <div className="infoModal">{t('pages:customer.info2')}</div>
                        <div className="nameModal">{t('pages:customer.name2')}</div>
                      </Col>
                      </Row>
                      </div>
                    </div>
                  </Modal>
                </Col>
                <Col xs={24} md={24} lg={12} xl={12}>
                  <div className="item-info-testimoals none-border" onClick={() => showModal(3)}>
                    <Row gutter={24}>
                      <Col xs={24} md={8} lg={8} xl={8}>
                        <img src="/static/img/GMO_avatar.jpeg" alt="hinhanh"/>
                      </Col>
                      <Col xs={24} md={16} lg={16} xl={16}>
                      <div className="company">{t('pages:customer.company3')}</div>
                        <div className="info">{t('pages:customer.info3')}</div>
                        <div className="info">{t('pages:customer.name3')}</div>
                        <div className="description">{t('pages:customer.descrtion3')}</div>
                      </Col>
                    </Row>
                  </div>
                  <Modal
                    title={t('pages:customer.titleModalFeedback')}
                    visible={visible3}
                    onOk={() => handleOk(3)}
                    onCancel={() => handleCancel(3)}
                    width="100%"
                    footer={[
                      <Button key="back" onClick={() => handleCancel(3)}>
                       {t('common:Button.close')}
                      </Button>,
                    ]}
                  >
                    <div className="item-detail-content-customer">
                      <img src="/static/img/GMO_avatar.jpeg" className="avataDEtailCustomer" alt="hinhanh"/>
                      <div className="nameModalMqn">{t('pages:customer.info3')} {t('pages:customer.name3')}</div>
                      <p>{t('pages:customer.descFull3')}</p>
                      <div className="item-content-company-custormer-mqn">
                      <Row>
                      <Col xs={24} md={10} lg={10} xl={10} offset={14}>
                        <div className="companyModal">{t('pages:customer.company3')}</div>
                        <div className="infoModal">{t('pages:customer.info3')}</div>
                        <div className="nameModal">{t('pages:customer.name3')}</div>
                      </Col>
                      </Row>
                      </div>
                    </div>
                  </Modal>
                </Col>
                <Col xs={24} md={24} lg={12} xl={12}>
                  <div className="item-info-testimoals none-border" onClick={() => showModal(4)}>
                   <Row gutter={24}>
                      <Col xs={24} md={8} lg={8} xl={8}>
                        <img src="/static/img/DaoNgocChien_avatar.jpeg" alt="hinhanh"/>
                      </Col>
                      <Col xs={24} md={16} lg={16} xl={16}>
                      <div className="company">{t('pages:customer.company4')}</div>
                        <div className="info">{t('pages:customer.info4')}</div>
                        <div className="info">{t('pages:customer.name4')}</div>
                        <div className="description">{t('pages:customer.descrtion4')}</div>
                      </Col>
                    </Row>
                  </div>
                  <Modal
                    title={t('pages:customer.titleModalFeedback')}
                    visible={visible4}
                    onOk={() => handleOk(4)}
                    onCancel={() => handleCancel(4)}
                    width="100%"
                    footer={[
                      <Button key="back" onClick={() => handleCancel(4)}>
                       {t('common:Button.close')}
                      </Button>,
                    ]}
                  >
                    <div className="item-detail-content-customer">
                      <img src="/static/img/DaoNgocChien_avatar.jpeg" className="avataDEtailCustomer" alt="hinhanh"/>
                      <div className="nameModalMqn">{t('pages:customer.info4')} {t('pages:customer.name4')}</div>
                      <p>{t('pages:customer.descFull4')}</p>
                      <div className="item-content-company-custormer-mqn">
                      <Row>
                      <Col xs={24} md={10} lg={10} xl={10} offset={14}>
                      <div className="companyModal">{t('pages:customer.company4')}</div>
                        <div className="infoModal">{t('pages:customer.info4')}</div>
                        <div className="nameModal">{t('pages:customer.name4')}</div>
                      </Col>
                      </Row>
                      </div>
                    </div>
                  </Modal>
                </Col>
              </Row>
            </div>

          </Carousel>
          </div>
        </div>
      </div>
    
      <div className="banner-logo-parter-mqn-main block">
        <div className="container">
          <Slider {...settings}>
            <div>
                <img src="/static/img/bca.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/boquocphong.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/HQ.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/Thue.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/khcn.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/20again.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/ariline.jepg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/Logo Final 2016-02.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/bachmai.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/benh-vien-da-khoa-phuong-dong.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/vinhbao.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/evn.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/hatech.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/luat.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/toyota.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/Mitsubishi_logo_standart.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/panasonic-vector-logo.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/singtel.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/viettek.jpeg" alt="hinhanh"/>
            </div>
            <div>
                <img src="/static/img/unitel.jpeg" alt="hinhanh"/>
            </div>
          </Slider>
        </div>
      </div>

      {/*<div id="news" className="item-new-main-pro-mqn block">
        <div className="container">
            <div className="title-banner-product">
              <div className="title">{t('pages:blog.title')}</div>
              <img src="/static/img/lineHeight.jpeg" alt="hinhanh"/>
            </div>
            <div className="item-news-main">
              <Carousel>
              <div className="item-new-blog-main-mqn">
                  <Row gutter={24}>
                    <Col xs={24} md={12} lg={8} xl={8}>
                      <div className="item-news-mqn" onClick={() => showModal(5)}>
                        <div className="avata-news">
                         <img src="/static/img/new1-1.jpeg" alt="hinhanh"/>
                        </div>
                        <div className="background-white-content">
                        <h1>{t('pages:blog.titleBlog1')}</h1>
                          <div className="datetime-news-mqn">
                            <img src="/static/img/calendar.jpeg" alt="hinhanh"/>
                            <p>{t('pages:blog.datePost1')}</p>
                            <span>{t('pages:blog.tags1')}</span>
                          </div>
                          <p>{t('pages:blog.description1')}</p>
                        </div>
                      </div>
                      <Modal
                        title={t('pages:blog.contentDetailBlog')}
                        visible={visible5}
                        onOk={() => handleOk(5)}
                        onCancel={() => handleCancel(5)}
                        width="100%"
                        footer={[
                          <Button key="back" onClick={() => handleCancel(5)}>
                            {t('common:Button.close')}
                          </Button>,
                        ]}
                      >
                        <div className="item-detail-blog-main-mqn">
                          <h1>{t('pages:blog.titleDetail1')}</h1>
                          <p>{t('pages:blog.itemContent1-1')}</p>
                          <p>{t('pages:blog.itemContent1-2')}</p>
                          <img src="/static/img/new1-1-1.jpeg" alt="hinhanh"/>
                          <p>{t('pages:blog.itemContent1-3')}</p>
                          <img src="/static/img/new1-2.jpeg" alt="hinhanh"/>
                          <p>{t('pages:blog.itemContent1-4')}</p>
                          <img src="/static/img/new1-3.jpeg" alt="hinhanh"/>
                          <p>{t('pages:blog.itemContent1-5')}</p>
                        </div>
                      </Modal>
                    </Col>

                    <Col xs={24} md={12} lg={8} xl={8}>
                      <div className="item-news-mqn" onClick={() => showModal(6)}>
                        <div className="avata-news">
                          <img src="/static/img/khoanh_khac_1_1.jpeg" alt="hinhanh"/>
                        </div>
                        <div className="background-white-content">
                        <h1>{t('pages:blog.titleBlog2')}</h1>
                          <div className="datetime-news-mqn">
                            <img src="/static/img/calendar.jpeg" alt="hinhanh"/>
                            <p>{t('pages:blog.datePost2')}</p>
                            <span>{t('pages:blog.tags2')}</span>
                          </div>
                          <p>{t('pages:blog.description2')}</p>
                        </div>
                      </div>
                      <Modal
                        title={t('pages:blog.contentDetailBlog')}
                        visible={visible6}
                        onOk={() => handleOk(6)}
                        onCancel={() => handleCancel(6)}
                        width="100%"
                        footer={[
                          <Button key="back" onClick={() => handleCancel(6)}>
                            {t('common:Button.close')}
                          </Button>,
                        ]}
                      >
                        <div className="item-detail-blog-main-mqn">
                          <h1>{t('pages:blog.titleDetail2')}</h1>
                          <p>{t('pages:blog.itemContent2-1')}</p>
                          <p>{t('pages:blog.itemContent2-2')}</p>
                          <p>{t('pages:blog.itemContent2-3')}</p>
                          <p>{t('pages:blog.itemContent2-4')}</p>
                          <p>{t('pages:blog.itemContent2-5')}</p>
                          <p>{t('pages:blog.itemContent2-6')}</p>
                          <p>{t('pages:blog.itemContent2-7')}</p>
                          <p>{t('pages:blog.itemContent2-8')}</p>
                          <p>{t('pages:blog.itemContent2-9')}</p>
                          <p>{t('pages:blog.itemContent2-10')}</p>
                          <p>{t('pages:blog.itemContent2-11')}</p>
                          <p>{t('pages:blog.itemContent2-12')}</p>
                          <p>{t('pages:blog.itemContent2-13')}</p>
                          <p>{t('pages:blog.itemContent2-14')}</p>
                          <p>{t('pages:blog.itemContent2-15')}</p>
                          <p>{t('pages:blog.itemContent2-16')}</p>
                          <p>{t('pages:blog.itemContent2-17')}</p>
                          <p>{t('pages:blog.itemContent2-18')}</p>
                          <p>{t('pages:blog.itemContent2-19')}</p>
                        </div>
                      </Modal>
                    </Col>

                    <Col xs={24} md={12} lg={8} xl={8}>
                      <div className="item-news-mqn" onClick={() => showModal(7)}>
                        <div className="avata-news">
                          <img src="/static/img/new6-4.jpeg" alt="hinhanh"/>
                        </div>
                        <div className="background-white-content">
                        <h1>{t('pages:blog.titleBlog3')}</h1>
                          <div className="datetime-news-mqn">
                            <img src="/static/img/calendar.jpeg" alt="hinhanh"/>
                            <p>{t('pages:blog.datePost3')}</p>
                            <span>{t('pages:blog.tags3')}</span>
                          </div>
                          <p>{t('pages:blog.description3')}</p>
                        </div>
                      </div>
                      <Modal
                        title={t('pages:blog.contentDetailBlog')}
                        visible={visible7}
                        onOk={() => handleOk(7)}
                        onCancel={() => handleCancel(7)}
                        width="100%"
                        footer={[
                          <Button key="back" onClick={() => handleCancel(7)}>
                             {t('common:Button.close')}
                          </Button>,
                        ]}
                      >
                        <div className="item-detail-blog-main-mqn">
                          <h1>{t('pages:blog.titleDetail3')}</h1>
                          <p>{t('pages:blog.itemContent3-1')}</p>
                          <p>{t('pages:blog.itemContent3-2')}</p>
                          <p>{t('pages:blog.itemContent3-3')}</p>
                          <img src="/static/img/new6-1.jpeg" alt="hinhanh"/>
                          <p>{t('pages:blog.itemContent3-4')}</p>
                          <p>{t('pages:blog.itemContent3-5')}</p>
                          <p>{t('pages:blog.itemContent3-6')}</p>
                          <img src="/static/img/new6-3.jpeg" alt="hinhanh"/>
                          <p><b>{t('pages:blog.itemContent3-7')}</b></p>
                          <p>{t('pages:blog.itemContent3-8')}</p>
                          <p>{t('pages:blog.itemContent3-9')}</p>
                          <p>{t('pages:blog.itemContent3-10')}</p>
                          <p>{t('pages:blog.itemContent3-11')}</p>
                          <p>{t('pages:blog.itemContent3-12')}</p>
                          <p>{t('pages:blog.itemContent3-13')}</p>
                          <p>{t('pages:blog.itemContent3-14')}</p>
                          <p>{t('pages:blog.itemContent3-15')}</p>
                          <p>{t('pages:blog.itemContent3-16')}</p>
                          <img src="/static/img/new6-5.jpeg" alt="hinhanh"/>
                          <img src="/static/img/new6-6.jpeg" alt="hinhanh"/>
                        </div>
                      </Modal>
                    </Col>

                    <Col xs={24} md={12} lg={8} xl={8}>
                      <div className="item-news-mqn">
                        <div className="avata-news">
                          <img src="/static/img/where_my_home.jpeg" alt="hinhanh"/>
                        </div>
                        <div className="background-white-content">
                        <h1>{t('pages:blog.titleBlog4')}</h1>
                          <div className="datetime-news-mqn">
                            <img src="/static/img/calendar.jpeg" alt="hinhanh"/>
                            <p>{t('pages:blog.datePost4')}</p>
                            <span>{t('pages:blog.tags4')}</span>
                          </div>
                          <p>{t('pages:blog.description4')}</p>
                        </div>
                      </div>
                    </Col>

                    <Col xs={24} md={12} lg={8} xl={8}>
                      <div className="item-news-mqn">
                        <div className="avata-news">
                          <img src="/static/img/new2-2.jpeg" alt="hinhanh"/>
                        </div>
                        <div className="background-white-content">
                        <h1>{t('pages:blog.titleBlog6')}</h1>
                          <div className="datetime-news-mqn">
                            <img src="/static/img/calendar.jpeg" alt="hinhanh"/>
                            <p>{t('pages:blog.datePost6')}</p>
                            <span>{t('pages:blog.tags6')}</span>
                          </div>
                          <p>{t('pages:blog.description6')}</p>
                        </div>
                      </div>
                    </Col>

                    <Col xs={24} md={12} lg={8} xl={8}>
                      <div className="item-news-mqn" onClick={() => showModal(8)}>
                        <div className="avata-news">
                          <img src="/static/img/sumup_2015_1_1.jpeg" alt="hinhanh"/>
                        </div>
                        <div className="background-white-content">
                        <h1>{t('pages:blog.titleBlog5')}</h1>
                          <div className="datetime-news-mqn">
                            <img src="/static/img/calendar.jpeg" alt="hinhanh"/>
                            <p>{t('pages:blog.datePost5')}</p>
                            <span>{t('pages:blog.tags5')}</span>
                          </div>
                          <p>{t('pages:blog.description5')}</p>
                        </div>
                      </div>
                      <Modal
                        title={t('pages:blog.contentDetailBlog')}
                        visible={visible8}
                        onOk={() => handleOk(8)}
                        onCancel={() => handleCancel(8)}
                        width="100%"
                        footer={[
                          <Button key="back" onClick={() => handleCancel(8)}>
                             {t('common:Button.close')}
                          </Button>,
                        ]}
                      >
                        <div className="item-detail-blog-main-mqn">
                          <h1>{t('pages:blog.titleDetail5')}</h1>
                          <p>{t('pages:blog.itemContent5-1')}</p>
                          <img src="/static/img/sumup_2015_1_1.jpeg" alt="hinhanh"/>
                          <p>{t('pages:blog.itemContent5-2')}</p>
                          <p>{t('pages:blog.itemContent5-3')}</p>
                          <p>{t('pages:blog.itemContent5-4')}</p>
                          <p>{t('pages:blog.itemContent5-5')}</p>
                          <p>{t('pages:blog.itemContent5-6')}</p>
                          <p>{t('pages:blog.itemContent5-7')}</p>
                          <img src="/static/img/sumup_2015_2.jpeg" alt="hinhanh"/>
                          <p>{t('pages:blog.itemContent5-8')}</p>
                          <img src="/static/img/sumup_2015_3.jpeg" alt="hinhanh"/>
                          <p>{t('pages:blog.itemContent5-9')}</p>
                          <img src="/static/img/sumup_2015_4.jpeg" alt="hinhanh"/>
                          <p>{t('pages:blog.itemContent5-10')}</p>
                          <img src="/static/img/sumup_2015_5.jpeg" alt="hinhanh"/>
                          <p>{t('pages:blog.itemContent5-11')}</p>
                          <img src="/static/img/sumup_2015_6.jpeg" alt="hinhanh"/>
                          <img src="/static/img/sumup_2015_8.jpeg" alt="hinhanh"/>
                          <img src="/static/img/sumup_2015_9.jpeg" alt="hinhanh"/>
                          <img src="/static/img/sumup_2015_10.jpeg" alt="hinhanh"/>
                        </div>
                      </Modal>
                    </Col>
                  </Row>
                </div>
              </Carousel>
          </div>
        </div>
      </div>
*/}    </div>
  </Layout>
}

export default Index
